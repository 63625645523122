// extracted by mini-css-extract-plugin
export var ImageWithHeadingWrapper = "ImageWithHeading-module--ImageWithHeadingWrapper--g7tLS";
export var headerTag = "ImageWithHeading-module--headerTag--2mt2R";
export var theImgWrap = "ImageWithHeading-module--theImgWrap--3Rq69";
export var imgWrapper = "ImageWithHeading-module--imgWrapper--3Q4iG";
export var theImg = "ImageWithHeading-module--theImg--20rHh";
export var imageCaption = "ImageWithHeading-module--imageCaption--tiUIB";
export var imageLeft = "ImageWithHeading-module--imageLeft--2lUHe";
export var imageRight = "ImageWithHeading-module--imageRight--oYaQL";
export var imageCenter = "ImageWithHeading-module--imageCenter--1bhD6";
export var textLeft = "ImageWithHeading-module--textLeft--3fPMG";
export var textRight = "ImageWithHeading-module--textRight--4TnJr";
export var subHeading = "ImageWithHeading-module--subHeading--YI0JV";