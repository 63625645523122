import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/SEO"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import Hero from "../components/Hero/Hero"
import BGImg from "../components/LargeBGImg/LargeBGImg"
import TwoColumn from "../components/TwoColumn/TwoColumn"
import ImageWithHeading from "../components/ImageWithHeading/ImageWithHeading"
import ImageGrid from "../components/ImageGrid/ImageGrid"

const Education = ({ data }) => {

  // Hero Sections
  const hero = data.hero.edges[0].node.frontmatter.heroImage.childImageSharp.gatsbyImageData;
  const heroAlt = data.hero.edges[0].node.frontmatter.heroImageAlt;
  const identifier = data.hero.edges[0].node.frontmatter.identifier;
  const heroDescription = data.hero.edges[0].node.frontmatter.description;

  const positions = {
    rowOneWrapper: {
      classes: "row-wrapper one",
      TextRow1: {
        classes: "mb-200",
          text1: {
            value: "The",
            classes: "sixty"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
      TextRow2: {
        classes: "",
          text1: {
            value: "Most",
            classes: "twenty"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
    },
    rowTwoWrapper: {
      classes: "row-wrapper two",
      TextRow1: {
        classes: "empty",
          text1: {
            value: "Important",
            classes: "sixty"
          },
          text2: {
            value: " ",
            classes: "empty"
          }
      },
      TextRow2: {
        classes: "empty",
          text1: {
            value: "Job",
            classes: "twenty Rightauto"
          },
          text2: {
            value: "In",
            classes: "ten"
          }
      },
      TextRow3: {
        classes: "",
          text1: {
            value: "",
            classes: "empty"
          },
          text2: {
            value: "America",
            classes: "forty Leftauto text-right"
          }
      },
    }
  }

  //bg
  const introContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Story')
  const publicContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'PUBLIC EDUCATION')
  const pitzerContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'PITZER COLLEGE')
  const msuContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'MSU')
  const k12Content = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Public K–12 Education')
  const kidContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'THE BROAD CENTER')
  const peresContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Peres center')


  //img w/ text
  const studentComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'student')
  const scholarComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'scholar')
  const childrenComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'children')
  const cohortComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'cohort')
  const forumComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'forum')
  const peresComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'peres')
  const prizeComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'prize')
  const announcementComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'announcement')
  const peresCenterComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'perescenter')

  //2 col
  const supportCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'support')
  const pitzerCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'pitzer')
  const chairmanCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'chairman')
  const managementCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'management')
  const familyCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'family')
  const k12Col = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'k12')
  const foundationCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'foundation')
  const broadCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'broadcenter')
  const broadBodyCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'broadcenterbody')
  const honorCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'honor')

  const whenbudding = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'whenbudding')
  const pitzerprovided = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'pitzerprovided')

  const classfoto = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'classfoto')

  return (
    <>
      <Seo title={"Eli Broad | Education"} description={"As a child of Lithuanian immigrants with few resources and an undiagnosed learning disability now known as dyslexia, the public schools Eli Broad attended in Detroit gave him opportunities to learn, grow and thrive—and he credited his teachers for helping him overcome his challenges."} />
      <Hero heroimage={hero} heroimagealt={heroAlt} identifier={identifier} positions={positions} description={heroDescription} />

      <BGImg content={introContent} />

      <BGImg content={publicContent} />

      <TwoColumn content={supportCol}/>

      <ImageWithText content={prizeComponent}  />

      <BGImg content={pitzerContent} />

      <TwoColumn content={pitzerCol} />

      <ImageWithHeading content={pitzerprovided} />

      <TwoColumn content={chairmanCol} />

      <BGImg content={msuContent} />

      <TwoColumn content={managementCol} type="top"  />

      <TwoColumn content={familyCol} type="bottom" />

      <ImageWithHeading content={whenbudding} />

      <ImageWithText content={announcementComponent}  />

      <BGImg content={k12Content} />

      <TwoColumn content={k12Col} />

      <ImageWithText content={studentComponent}  />

      <ImageWithText content={scholarComponent}  />

      <ImageWithText content={childrenComponent}  />

      <TwoColumn content={foundationCol} />

      <BGImg content={kidContent} />

      <TwoColumn content={broadCol} type="top" />

      <TwoColumn content={broadBodyCol} type="bottom" />

      <ImageWithText content={cohortComponent}  />

      <ImageWithText content={forumComponent}  />

      <ImageGrid content={classfoto} />

      <BGImg content={peresContent} />

      <TwoColumn content={honorCol} />

      <ImageWithText content={peresComponent}  />

      <ImageWithText content={peresCenterComponent}  />

      <div class="continue-link">
        <Link to="/science">Continue to Science</Link>
      </div>

    </>
  )
}


export default Education

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {frontmatter: {identifier: {eq: "education"}}}) {
      edges {
        node {
          frontmatter {
            heroImage {
              childImageSharp {
                gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            heroImageAlt
            identifier
            intro
            title
            description
          }
        }
      }
    }
    imageWithText: allMarkdownRemark(filter: {frontmatter: {component: {eq: "imageWithText"}, page: {eq: "education"}}}) {
      edges {
        node {
          ...imageWithTextQuery
        }
      }
    }
    largeBGImg: allMarkdownRemark(filter: {frontmatter: {component: {eq: "LargeBGImg"}, page: {eq: "education"}}}) {
      edges {
        node {
          ...largeBGImgQuery
        }
      }
    }
    twoColumn: allMarkdownRemark(filter: {frontmatter: {page: {eq: "education"}, component: {eq: "twocolumn"}}}) {
      edges {
        node {
          ...twoColumnQuery
        }
      }
    }
    imageWithHeading: allMarkdownRemark(
      filter: {frontmatter: {component: {eq: "imageWithHeading"}, page: {eq: "education"}}}
    ) {
      edges {
        node {
          ...imageWithHeadingQuery
        }
      }
    }
    imageGrid: allMarkdownRemark(filter: {frontmatter: {page: {eq: "education"}, component: {eq: "imageGrid"}}}) {
      edges {
        node {
          ...imageGridQuery
        }
      }
    }
  }
`

