import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import * as classes from "./ImageWithHeading.module.scss"
import getWindowDimensions from  "../../utilities/windowDimensions"

const ImageWithHeading = ({
  content,
  reverseOnMobile = false,
  imageFloat = 'right',
  alignTop = false
}) => {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    const ImageWithHeading = content[0].node.frontmatter

    const image = getImage(ImageWithHeading.image)
    // const img = getImage(ImageWithHeading.image)
    const imageBottom = getImage(ImageWithHeading.imageBottom)

    const headingTag = content[0].node.frontmatter.headingTag
    const text = content[0].node.frontmatter.text
    const subHeading = content[0].node.frontmatter.subHeading
    const identifier = content[0].node.frontmatter.identifier
    const altText = content[0].node.frontmatter.altText
    const imageCaption = content[0].node.frontmatter.imageCaption

    let viewPort;
    if (!windowDimensions) {
      viewPort = 1200
    } else {
      viewPort = windowDimensions.width
    }

    let imagePosition
    let textPosition

    let desktopImageWidth
    let mobileImageWidth

    let desktopTextWidth
    let mobileTextWidth

    let textPushTopDesktop

    if (content[0].node.frontmatter.options) {

      imagePosition = content[0].node.frontmatter.options[0].imagePosition
      textPosition = content[0].node.frontmatter.options[0].textPosition

      desktopImageWidth = content[0].node.frontmatter.options[0].desktopImageWidth
      mobileImageWidth = content[0].node.frontmatter.options[0].mobileImageWidth

      desktopTextWidth = content[0].node.frontmatter.options[0].desktopTextWidth
      mobileTextWidth = content[0].node.frontmatter.options[0].mobileTextWidth

      textPushTopDesktop = content[0].node.frontmatter.options[0].textPushTopDesktop
    }


    let ImgWrapper;
    ImgWrapper = styled.div`
    `

    let headerTag;
    if (headingTag) {
      if (headingTag === 'h3') {
        headerTag = styled.h3`
        `
      } else if (headingTag === 'h4') {
        headerTag = styled.h4`
        `
      }
    }
    else {
      headerTag = styled.h3`
      `
    }

    return (
      <div className={`
                    ${classes.ImageWithHeadingWrapper}
                    ${imagePosition === 'left' ? classes.imageLeft : imagePosition === 'right' ? classes.imageRight : classes.imageCenter}
                    ${textPosition === 'left' ? classes.textLeft : textPosition === 'right' ? classes.textRight : ''}
                    `
                    }
          data-component={identifier}
        >
        <div>
          <ImgWrapper className={classes.imgWrapper}
            style={
              (viewPort >= 1200  ) ?
                {
                  paddingBottom: `${textPushTopDesktop}px`
                }
              :
              (viewPort <= 768) ?
                {
                }
                :
                {
                }
            }
          >

          <div className={classes.theImgWrap}
            style={
              (imagePosition !== 'center') ?
                (viewPort >= 1200  ) ?
                {
                  width: `${desktopImageWidth}`
                }
                :
                (viewPort <= 768 ) ?
                {
                  width: `${mobileImageWidth}`
                }
                :
                {
                  width: `${mobileTextWidth}`
                }
              :
              {}
            }
                          >
            { ((imagePosition === 'right' ) || (imagePosition === 'left' ) ) &&
              <>
              <GatsbyImage
                className={imageCaption && classes.theImg}
                image={image}
                alt={altText}
                />

                  <GatsbyImage
                  className={imageCaption && classes.theImg}
                  image={imageBottom}
                  alt={altText}
                  />
                </>

            }

            { (imagePosition === 'center' ) &&
            <GatsbyImage
                className={imageCaption && classes.theImg}
                image={image}
                alt={altText}
                // style={
                //   {
                //     width: `100%`
                //   }
                // }
                />
              }

            { imageCaption && <p className={classes.imageCaption}>{imageCaption}</p> }
          </div>


            { ((textPosition === 'right' ) || (textPosition === 'left' ) ) &&
              <>
              <headerTag className={classes.headerTag}
                style={
                  (viewPort >= 1200  ) ?
                    {
                      width: `${desktopTextWidth}`,
                      marginTop: `${textPushTopDesktop}px`
                    }
                  :
                  (viewPort <= 768) ?
                    {
                      width: `${mobileTextWidth}`
                    }
                    :
                    {
                      width: `${mobileTextWidth}`
                    }
                }
              >
                <div>{text}</div>
              { subHeading && <p className={classes.subHeading}>{subHeading}</p> }
              </headerTag>
              </>
            }
            { (textPosition === 'center') &&
              <headerTag className={classes.headerTag}
                  style={
                    {
                      maxWidth: `70%`,
                      margin: `0 auto`,
                      textAlign: `center`
                    }
                  }
                >
                {text}
                </headerTag>
              }
          </ImgWrapper>


        </div>
      </div>
    )
  }


export default ImageWithHeading
